<template lang="pug">
.analytics__cf
    v-container
        v-card.ml-8(light)
            v-card-title.brand.white--text.py-2 Conversion Factors
            v-card-text
                 v-data-table(
                    :headers="headers"
                    :items="factors"
                    :items-per-page="5"
                    dense
                )

                    template(v-slot:top)
                        v-toolbar(flat)
                            v-spacer
                            v-dialog(
                                v-model='dialog'
                                max-width='500px'
                                light
                            )
                                template(v-slot:activator="{on, attrs}")
                                    v-btn.brand.white--text(
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                    ) New Factor
                                v-card(class="w-100")
                                    v-card-title.brand.white--text {{ formTitle }}
                                    v-card-text
                                        v-container(class="mt-5")
                                            v-form(ref="form" v-model="valid")
                                                v-row
                                                    v-col(cols="12" sm="6" md="6")
                                                        v-select(
                                                            v-model="editedItem['fromUom'].uom_code"
                                                            :items="units"
                                                            :label="fromLabel"
                                                            :readonly="editedIndex > -1"
                                                            color='brand'
                                                            dense
                                                            item-text="name"
                                                            item-value="uom_code"
                                                            required
                                                        )
                                                    v-col(cols="12" sm="6" md="6")
                                                        v-select(
                                                            v-model="editedItem['toUom'].uom_code"
                                                            :items="units"
                                                            :label="toLabel"
                                                            :readonly="editedIndex > -1"
                                                            color='brand'
                                                            item-text="name"
                                                            item-value="uom_code"
                                                            dense
                                                            required
                                                        )
                                                v-row
                                                    v-col(cols="12" sm="6" md="6")
                                                        v-text-field(
                                                            v-model="editedItem.conversion_factor"
                                                            :rules="[rules.required, rules.isNumber]"
                                                            label="Conversion Factor"
                                                            color='brand'
                                                            dense
                                                            clearable
                                                        )
                                                    v-col(cols="12" sm="6" md="6")
                                                        v-text-field(
                                                            v-model="editedItem.offset"
                                                            :rules="[rules.isNumber]"
                                                            label="Offset"
                                                            color="brand"
                                                            dense
                                                            clearable
                                                        )
                                                v-row(dense)
                                                    v-col(cols="12")
                                                        v-textarea(
                                                            v-model.trim="editedItem.description"
                                                            clearable
                                                            color="brand"
                                                            label="Description"
                                                            auto-grow
                                                            counter="250"
                                                            filled
                                                            shaped
                                                            :rules="[rules.maxChars]"
                                                        )


                                    v-card-actions
                                        v-spacer
                                        v-btn.actions.orange.white--text(
                                            text
                                            @click="close"
                                        ) Cancel
                                        v-btn.actions.brand.white--text(
                                            text
                                            :disabled="!valid"
                                            @click="save"
                                        ) Save
                            v-dialog(
                                v-model='dialogDelete'
                                max-width='500px'
                                light
                            )
                                v-card
                                    v-card-title.brand.white--text Confirm Delete
                                    v-card-text
                                        span.text-h6 Are you sure you want to delete this factor?
                                    v-card-actions
                                        v-spacer
                                        v-btn.orange.white--text(
                                            text
                                            @click="closeDelete"
                                        ) Cancel
                                        v-btn.brand.white--text(
                                            text
                                            @click="deleteItemConfirm"
                                        ) OK

                    template(v-slot:item.actions="{ item }")
                        v-icon(
                            small
                            color="#333"
                            @click="editItem(item)"
                        ) edit
                        v-icon(
                            small
                            color="red"
                            @click="deleteItem(item)"
                        ).pl-2 delete

</template>

<style scoped lang="css">

.actions.v-btn--disabled.v-btn--text {
    background-color: #ddd !important;
}
.actions.v-btn--disabled > span {
    color: #777 !important;
    background-color: #ddd !important;
}

</style>


<script lang="js">

export default {
    meta: {
        role: 'analytics-uom'
    },
    components: {
    },
    emits: [
        'analyticsGetFactors',
        'analyticsGetUnits',
    ],
    data () {
        return {
            headers: [
                { text: 'Fid', value: 'from_uom_id' },
                { text: 'From', value: 'fromUom.uom_code' },
                { text: 'Tid', value: 'to_uom_id' },
                { text: 'To', value: 'toUom.uom_code' },
                { text: 'cf', value: 'conversion_factor' },
                { text: 'Actions', value: 'actions', sortable: false }
            ],
            factors: [],
            units: [],
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            editedItem: {
                fromUom: {
                    uom_code: '',
                    name: '',
                },
                toUom: {
                    uom_code: '',
                    name: '',
                },
                conversion_factor: null,
                offset: 0,
                description: '',
                is_active: true,
            },
            defaultItem: {
                fromUom: {
                    uom_code: '',
                    name: '',
                },
                toUom: {
                    uom_code: '',
                    name: '',
                },
                conversion_factor: null,
                offset: 0,
                description: '',
                is_active: true,
            },
            dialogTitle: '',
            valid: false,
            rules: {
                required: value => !!value || 'Required.',
                isNumber: value => !isNaN(value) || 'Must be a number',
                maxChars: value => value?.length <= 250 || 'Max 250 characters',
            }
        };
    },
    methods:{
        initialize () {
            this.getUnits();
            this.getFactors();
        },
        getUnits () {
            this.socketEmit('analyticsGetUnits', null, ({units, error}) => {
                if(units) {
                    this.$set(this, 'units', units);
                } else {
                    this.$set(this, 'units', []);
                    console.error('Error fetching units:', error);
                }
            });
        },
        getFactors () {
            this.socketEmit('analyticsGetFactors', null, ({factors, error}) => {
                if(factors) {
                    this.$set(this, 'factors', factors);
                } else {
                    this.$set(this, 'factors', []);
                    console.error('Error fetching factors:', error);
                }
            });
        },
        close () {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.valid = false;
            });
        },

        deleteItem (item) {
            this.editedIndex = this.factors.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm () {
            this.factors.splice(this.editedIndex, 1);
            this.socketEmit('analyticsDeleteFactor', this.editedItem, this.closeDelete);
        },

        closeDelete () {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        editItem (item) {
            this.editedIndex = this.factors.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        save () {
            if (this.editedIndex > -1) {
                this.$set(this.factors, this.editedIndex, {...this.editedItem});
            } else {
                this.factors.push({...this.editedItem});
            }
            this.socketEmit('analyticsSaveFactor', this.editedItem, () => {
                this.close();
            });

        },
    },
    watch: {
        dialog (val) {
            val || this.close();
        },

        dialogDelete (val) {
            val || this.closeDelete();
        },
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Factor' : 'Edit Factor';
        },
        fromLabel () {
            return this.editedIndex === -1 ? 'From Unit' : 'From Unit (read-only)';
        },
        toLabel () {
            return this.editedIndex === -1 ? 'To Unit' : 'To Unit (read-only)';
        }
    },
    mounted () {
        this.initialize();
    },
    created () {
        this.$store.dispatch('connectSocket');
    },
};
</script>
